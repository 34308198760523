import React, { Fragment } from 'react';

const tableColumns = [
    { header: "Name" },
    { header: "Qty On Hand" },
    { header: "Qty Used" },
    { header: "Box Barcode" },
    { header: "Item Barcode" },
    { header: "Qty Per Box" },
    { header: "Qty Per Package" },
    { header: "Min. Qty Before Reorder" },
    { header: "Target Qty To Reorder" }
];


export const ReportResInventoryList = ({reportData}) => {
    var groups = Array.from(new Set(reportData.inventory.map(t => {
        return t.Group;
    }))).sort();

    return (
        <Fragment>
            <div className="col-span-10 print:-ml-4">
                <div className="rounded-lg border-black border px-3 print:border-none print:px-0">
                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg sm:max-h-[75vh] print:overflow-x-visible">
                        <table className="text-xs text-left text-gray-500">
                            <thead className="text-xs md:text-sm font-semibold text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    {tableColumns.map((col, i) => {
                                        return (<th key={"col"+i} scope="col" className="text-center px-2 py-3">{col.header}</th>);
                                    })}
                                </tr>
                            </thead>
                            <tbody className="text-xs md:text-sm font-semibold text-gray-700 uppercase bg-gray-50">
                                {groups.map((g, i) => {
                                    const data = reportData.inventory.filter(inv => g === inv.Group);
                                    return (
                                        <Fragment key={"row"+i}>
                                            <tr className="text-[#008000] font-bold"><td className="text-center px-2">{g}</td><td/><td/><td/><td/><td/><td/><td/></tr>
                                            {data.map((inv, j)=> {
                                                return (
                                                    <tr key={"roww"+j} className="bg-white border-b hover:bg-gray-50">
                                                        <td className="text-center pl-2 py-1">{inv.Name}</td>
                                                        <td className="text-center">{inv.QtyOnHand}</td>
                                                        <td className="text-center">{inv.QtyUsed}</td>
                                                        <td className="text-center">{inv.BoxBarcode}</td>
                                                        <td className="text-center">{inv.ItemBarcode}</td>
                                                        <td className="text-center">{inv.QtyPerBox}</td>
                                                        <td className="text-center">{inv.QtyPerPackage}</td>
                                                        <td className="text-center">{inv.MinQtyBeforeReorder}</td>
                                                        <td className="text-center">{inv.TargetQtyToReorder}</td>
                                                    </tr>
                                                )
                                            })}
                                        </Fragment>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Fragment>
    );
  }
  
  export default ReportResInventoryList;