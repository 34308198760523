import React, { Fragment } from 'react';
import CurrencyFormat from 'react-currency-format';

const tableColumns = [
    { header: "Month" },
    { header: "Invoice Count" },
    { header: "Subtotal" },
    { header: "Discount" },
    { header: "Tax" },
    { header: "Total" },
    { header: "Averaget" },
    { header: "Max" },
    { header: "Percentage" }
];

export const ReportRetSalesByMonth = ({reportData}) => {
    return (
        <Fragment>
            <div className="col-span-10">
                <div className="rounded-lg border-black border px-3 print:border-none print:px-0">
                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg sm:max-h-[75vh] print:overflow-x-visible">
                        <table className="text-xs text-left text-gray-500">
                            <thead className="text-xs md:text-sm lg:text-base font-semibold text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    {tableColumns.map((col, i) => {
                                        return (<th key={"col"+i} scope="col" className="text-center px-2 py-3">{col.header}</th>);
                                    })}
                                </tr>
                            </thead>
                            <tbody className="text-sm md:text-base font-semibold text-gray-700 uppercase bg-gray-50">
                                {reportData.month.filter(mon=> mon.Total!==0).map((m, i) => {
                                    return (
                                        <tr key={"row"+i} className="bg-white border-b hover:bg-gray-50">
                                            <td className="text-center p-2">{m.YearAndMonth}</td>
                                            <td className="text-center"><CurrencyFormat value={m.Qty} displayType={'text'} thousandSeparator={true}/></td>
                                            <td className="text-center"><CurrencyFormat value={m.Subtotal} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                            <td className="text-center"><CurrencyFormat value={m.Discount} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                            <td className="text-center"><CurrencyFormat value={m.Tax} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                            <td className="text-center"><CurrencyFormat value={m.Total} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                            <td className="text-center"><CurrencyFormat value={m.Average} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                            <td className="text-center"><CurrencyFormat value={m.Max} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                            <td className="text-center px-2"><CurrencyFormat value={m.Total/reportData.month.reduce((a,v)=>a=a+v.Total,0)*100} displayType={'text'} suffix={' %'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/></td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            <tfoot className="text-sm md:text-base font-semibold text-gray-700 uppercase bg-gray-50">
                                <tr className="table-borderless border-t border-black text-[#00f] font-bold">
                                    <td className="text-center p-2">Total</td>
                                    <td className="text-center"><CurrencyFormat value={reportData.month.reduce((a,v)=> a=a+v.Qty, 0)} displayType={'text'} thousandSeparator={true}/></td>
                                    <td className="text-center"><CurrencyFormat value={reportData.month.reduce((a,v)=> a=a+v.Subtotal, 0)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                    <td className="text-center"><CurrencyFormat value={reportData.month.reduce((a,v)=> a=a+v.Discount, 0)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                    <td className="text-center"><CurrencyFormat value={reportData.month.reduce((a,v)=> a=a+v.Tax, 0)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                    <td className="text-center px-2"><CurrencyFormat value={reportData.month.reduce((a,v)=> a=a+v.Total, 0)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                    <td/><td/><td/>
                                </tr>
                            </tfoot>   
                        </table>
                    </div>
                </div>
            </div>
        </Fragment>
    );
  }
  
  export default ReportRetSalesByMonth;