import React, { Fragment } from 'react';
import CurrencyFormat from 'react-currency-format';

export const ReportRetSalesSummary = ({reportData}) => {
    return (
        <Fragment>
            <div className="col-span-10 md:col-span-5 print:col-span-5">
                <div className="rounded-lg border-black border px-4">
                    <div className="p-3 border-b border-black">
                        <div className="text-xl md:text-2xl font-semibold text-gray-900">
                            Sales by Department
                        </div>
                    </div>
                    <div className="p-3">
                        <div className="grid grid-cols-10 gap-x-4 sm:gap-x-8 gap-y-3">
                            {reportData.dept && reportData.dept.map((d, i) => {
                                return (<Fragment key={"dept"+i}>
                                    <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right">{d.Name}</div>
                                    <div className="text-xs sm:text-sm md:text-base col-span-2 font-semibold text-center"><CurrencyFormat value={d.Qty} displayType={'text'} thousandSeparator={true}/></div>
                                    <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right"><CurrencyFormat value={d.Subtotal} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/></div>
                                </Fragment>)
                            })}
                        </div>
                    </div>
                    <div className="p-3 border-t border-black">
                        <div className="grid grid-cols-10 gap-x-4 sm:gap-x-8 gap-y-3">
                            {reportData.deptResult ? <Fragment>
                                <div className="text-xs sm:text-sm md:text-base col-span-6 font-semibold text-right">Subtotal:</div>
                                <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right"><CurrencyFormat value={reportData.deptResult[0].Subtotal || 0} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/></div>
                                <div className="text-xs sm:text-sm md:text-base col-span-6 font-semibold text-right">Tax:</div>
                                <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right"><CurrencyFormat value={reportData.deptResult[0].Tax || 0} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/></div>
                            </Fragment>: <></>}
                        </div>
                    </div>
                    <div className="p-3 border-t border-black">
                        <div className="grid grid-cols-10 gap-x-4 sm:gap-x-8 gap-y-3">
                            {reportData.deptResult ? <Fragment>
                                <div className="text-xs sm:text-sm md:text-base col-span-6 font-semibold text-right">Subtotal:</div>
                                <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right text-[#00f]"><CurrencyFormat className="text-blue"value={reportData.deptResult[0].Total || 0} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/></div>
                            </Fragment>: <></>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-10 md:col-span-5 print:col-span-5">
                <div className="rounded-lg border-black border px-4">
                    <div className="p-3 border-b border-black">
                        <div className="text-xl md:text-2xl font-semibold text-gray-900">
                            Payments
                        </div>
                    </div>
                    <div className="p-3">
                        <div className="grid grid-cols-10 gap-x-4 sm:gap-x-8 gap-y-3">
                            {reportData.income && reportData.income.map((d, i) => {
                                return (<Fragment key={"income"+i}>
                                    <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right">{d.Name}</div>
                                    <div className="text-xs sm:text-sm md:text-base col-span-2 font-semibold text-right"><CurrencyFormat value={d.Qty} displayType={'text'} thousandSeparator={true}/></div>
                                    <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right"><CurrencyFormat value={d.Amount} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/></div>
                                </Fragment>)
                            })}
                        </div>
                    </div>
                    <div className="p-3 border-t border-black">
                        <div className="grid grid-cols-10 gap-x-4 sm:gap-x-8 gap-y-3">
                            {reportData.income && reportData.accountPayment ? <Fragment>
                                <div className="text-xs sm:text-sm md:text-base col-span-6 font-semibold text-right">Subtotal:</div>
                                <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right"><CurrencyFormat value={reportData.income.reduce((a,v)=> a=a+v.Amount,0)} displayType={'text'} thousandSeparator={true} prefix={'$ '}  decimalScale={2} fixedDecimalScale={true}/></div>
                                <div className="text-xs sm:text-sm md:text-base col-span-6 font-semibold text-right">Acc. Payments:</div>
                                <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right text-[#FF0000]"><CurrencyFormat className="text-red" value={reportData.accountPayment.reduce((a,v)=> a=a+v.Amount,0)} displayType={'text'} thousandSeparator={true} prefix={'-$ '}  decimalScale={2} fixedDecimalScale={true}/></div>
                            </Fragment>: <></>}
                        </div>
                    </div>
                    <div className="p-3 border-t border-black">
                        <div className="grid grid-cols-10 gap-x-4 sm:gap-x-8 gap-y-3">
                            {reportData.income && reportData.payout && reportData.accountPayment ? <Fragment>
                                <div className="text-xs sm:text-sm md:text-base col-span-6 font-semibold text-right">G. Subtotal:</div>
                                <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right text-[#00f]">
                                    <CurrencyFormat className="text-blue" value={reportData.income.reduce((a,v)=> a=a+v.Amount,0)-reportData.accountPayment.reduce((a,v)=> a=a+v.Amount,0)} displayType={'text'} thousandSeparator={true} prefix={'$ '}  decimalScale={2} fixedDecimalScale={true}/>
                                </div>
                                <div className="text-xs sm:text-sm md:text-base col-span-6 font-semibold text-right">Payout:</div>
                                <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right text-[#FF0000]">
                                    <CurrencyFormat className="text-red" value={reportData.payout.reduce((a,v)=> a=a+v.Amount,0)-reportData.accountPayment.reduce((a,v)=> a=a+v.Amount,0)} displayType={'text'} thousandSeparator={true} prefix={'$ '}  decimalScale={2} fixedDecimalScale={true}/>
                                </div>
                                <div className="text-xs sm:text-sm md:text-base col-span-6 font-semibold text-right">Acc/CR/GC/Cert:</div>
                                <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right text-[#FF0000]">
                                    <CurrencyFormat className="text-red" value={reportData.income.filter((i)=> i.CategoryIDC ==="GC" || i.CategoryIDC ==="CR" || i.CategoryIDC ==="GT" || i.CategoryIDC ==="AC")
                                                .reduce((a,v)=> a=a+v.Amount,0)} displayType={'text'} thousandSeparator={true} prefix={'-$ '}  decimalScale={2} fixedDecimalScale={true}/>
                                </div>
                            </Fragment>: <></>}
                        </div>
                    </div>
                    <div className="p-3 border-t border-black">
                        <div className="grid grid-cols-10 gap-x-4 sm:gap-x-8 gap-y-3">
                            {reportData.income && reportData.payout && reportData.accountPayment ? <Fragment>
                                <div className="text-xs sm:text-sm md:text-base col-span-6 font-semibold text-right">Net Total:</div>
                                <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right text-[#00f]">
                                <CurrencyFormat value={
                                                reportData.income.reduce((a,v)=> a=a+v.Amount,0)-reportData.accountPayment.reduce((a,v)=> a=a+v.Amount,0)-
                                                reportData.payout.reduce((a,v)=> a=a+v.Amount,0)-reportData.income.filter((i)=> i.CategoryIDC ==="GC" || i.CategoryIDC ==="CR" || i.CategoryIDC ==="GT" || i.CategoryIDC ==="AC")
                                                .reduce((a,v)=> a=a+v.Amount,0)} displayType={'text'} thousandSeparator={true} prefix={'$ '}  decimalScale={2} fixedDecimalScale={true}/>
                                </div>
                            </Fragment>: <></>}
                        </div>
                    </div>
                </div>
                <div className="rounded-lg border-black border px-4 mt-6">
                    <div className="p-3 border-b border-black">
                        <div className="text-xl md:text-2xl font-semibold text-gray-900">Discounts</div>
                    </div>
                    <div className="p-3">
                        <div className="grid grid-cols-10 gap-x-4 sm:gap-x-8 gap-y-3">
                            {reportData.discount && reportData.discount.map((d, i) => {
                                return (<Fragment key={"dept"+i}>
                                    <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right">{d.Name}</div>
                                    <div className="text-xs sm:text-sm md:text-base col-span-2 font-semibold text-right"><CurrencyFormat value={d.Qty} displayType={'text'} thousandSeparator={true}/></div>
                                    <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right text-[#FF0000]"><CurrencyFormat value={d.Total} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/></div>
                                </Fragment>)
                            })}
                        </div>
                    </div>
                    <div className="p-3 border-t border-black">
                        <div className="grid grid-cols-10 gap-x-4 sm:gap-x-8 gap-y-3">
                            {reportData.discount ? <Fragment>
                                <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right">Discount Total:</div>
                                <div className="text-xs sm:text-sm md:text-base col-span-2 font-semibold text-right"><CurrencyFormat value={reportData.discount.reduce((a,v)=> a=a+v.Qty,0)} displayType={'text'} thousandSeparator={true}/></div>
                                <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right text-[#FF0000]"><CurrencyFormat className="text-red" value={reportData.discount.reduce((a,v)=> a=a+v.Total,0)} displayType={'text'} thousandSeparator={true} prefix={'$ '}  decimalScale={2} fixedDecimalScale={true}/></div>
                            </Fragment>: <></>}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
  }
  
  export default ReportRetSalesSummary;