import React, { Fragment, useState } from 'react';
import CurrencyFormat from 'react-currency-format';

const tableColumns = [
    { header: "Order Date" },
    { header: "Payment Date" },
    { header: "Order #" },
    { header: "Inv #" },
    { header: "Table #" },
    { header: "Order Type" },
    { header: "Pay Type" },
    { header: "Base Amount" },
    { header: "Tips" },
    { header: "Total" }
];

export const ReportResTipsByEmployee = ({reportData}) => {
    const [selectedEmployee, setSelectedEmployee] = useState('');
    var employeeLibrary = {};
    var employees = Array.from(new Set(reportData.tip.map(t => {
        employeeLibrary[t.EmployeeIDN] = t.Server;
        return t.EmployeeIDN;
    }))).sort();

    return (
        <Fragment>
            <div className="col-span-10">
                <div className="rounded-lg border-black border px-4 print:border-none print:px-0">
                    <div className="py-3 border-b border-black">
                        <div className="text-xl md:text-2xl font-semibold text-gray-900">
                            <div className="col-span-4 sm:col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 text-left">Choose Employee</label>
                                <select onChange={(e) => {setSelectedEmployee(e.target.value)}} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5">
                                    <option value="">Everyone</option>
                                    {employees.map(e => <option key={e} value={e}>{employeeLibrary[e]}</option>)}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="relative overflow-x-auto shadow-md print:shadow-none sm:rounded-lg mb-3 sm:max-h-[70vh] print:overflow-x-visible">
                        <table className="text-xs text-left text-gray-500">
                            <thead className="text-xs md:text-sm font-semibold text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    {tableColumns.map((col, i) => {
                                        return (<th key={"col"+i} scope="col" className="text-center px-2 py-4">{col.header}</th>);
                                    })}
                                </tr>
                            </thead>
                            <tbody className="text-xs md:text-sm font-semibold text-gray-700 uppercase bg-gray-50">
                                {employees.map((r, i) => {
                                    if(selectedEmployee !== "" && parseInt(selectedEmployee) !== parseInt(r)) return <Fragment key={"row"+i}></Fragment>;
                                    const data = reportData.tip.filter(t => r === t.EmployeeIDN);
                                    return (
                                        <Fragment key={"row"+i}>
                                            <tr className="text-[#008000] font-bold"><td className="text-center px-2">{employeeLibrary[r]}</td><td/><td/><td/><td/><td/><td/><td/><td/><td/></tr>
                                            {data.map((d, i)=> {
                                                return (
                                                    <tr key={"roww"+i}className="bg-white border-b hover:bg-gray-50">
                                                        <td className="text-center py-1">{d.OrderDate.replaceAll('-','/').replaceAll('T',' ').substring(0,16)}</td>
                                                        <td className="text-center">{d.PaymentDate.replaceAll('-','/').replaceAll('T',' ').substring(0,16)}</td>
                                                        <td className="text-center">{d.OrderNum}</td>
                                                        <td className="text-center">{d.InvoiceNumber}</td>
                                                        <td className="text-center">{d.TableNum}</td>
                                                        <td className="text-center">{d.OrderType}</td>
                                                        <td className="text-center">{d.PaymentType}</td>
                                                        <td className="text-center"><CurrencyFormat value={d.BaseAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                                        <td className="text-center"><CurrencyFormat value={d.Tips} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                                        <td className="text-center"><CurrencyFormat value={d.Total} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                                    </tr>
                                                )
                                            })}
                                            <tr className="table-borderless border-top border-dark text-[#008000] font-bold">
                                                <td/><td/><td/><td/><td/><td/><td/><td/>
                                                <td className="text-center"><CurrencyFormat value={data.reduce((a,v)=> a=a+parseFloat(v.Tips),0)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                            </tr>
                                        </Fragment>
                                    )
                                })}
                            </tbody>
                            {selectedEmployee === "" ? 
                                <tfoot className="text-xs md:text-sm font-semibold text-gray-700 uppercase bg-gray-50">
                                    <tr className="table-borderless border-t border-black text-[#00f] font-bold">
                                        <td/><td/><td/><td/><td/><td/><td/><td/>
                                        <td className="text-center py-1"><CurrencyFormat value={reportData.tip.reduce((a,v)=> a=a+parseFloat(v.Tips),0)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                        <td/>
                                    </tr>
                                </tfoot>
                                : <Fragment></Fragment>
                            }       
                        </table>
                    </div>
                </div>
            </div>
        </Fragment>
    );
  }
  
  export default ReportResTipsByEmployee;