import './App.css';
import PageHome from "./components/PageHome";
import PageLogin from "./components/PageLogin";
import PageAdmin from "./components/PageAdmin";
import PageUser from "./components/PageUser";
import PageReport from "./components/PageReport";
import { Routes, Route } from "react-router-dom"

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={ <PageHome/> }>
          <Route path="home" element={ <PageUser/> }/>
          <Route path="report" element={ <PageReport/> }/>
          <Route path="admin" element={ <PageAdmin/> } />
        </Route>
        <Route path="login" element={ <PageLogin/> } />
        <Route path="/*" element={<PageHome/>} />
      </Routes>
    </div>
  );
}

export default App;
