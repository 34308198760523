import React, { Fragment } from 'react';
import CurrencyFormat from 'react-currency-format';

const tableColumns = [
    { header: "Start Date" },
    { header: "End Date" },
    { header: "Start User" },
    { header: "End User" },
    { header: "Station #" },
    { header: "Start Amount" },
    { header: "End Amount" },
    { header: "Difference" }
];

export const ReportRetDrawerAmount = ({reportData}) => {
    return (
        <Fragment>
            <div className="col-span-10">
                <div className="rounded-lg border-black border px-4 print:border-none print:px-0">
                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg sm:max-h-[75vh] print:overflow-x-visible">
                        <table className="text-xs text-left text-gray-500">
                            <thead className="text-xs md:text-sm lg:text-base print:text-sm font-semibold text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    {tableColumns.map((col, i) => {
                                        return (<th key={"col"+i} scope="col" className="text-center px-2 py-3">{col.header}</th>);
                                    })}
                                </tr>
                            </thead>
                            <tbody className="text-sm md:text-base print:text-sm font-semibold text-gray-700 uppercase bg-gray-50">
                                {reportData.drawer.map((r, i) => {
                                    return (
                                        <tr key={"row"+i} className="bg-white border-b hover:bg-gray-50">
                                            <td className="text-center px-2 py-1">{r.StartDate.replaceAll('-','/').replaceAll('T',' ').substring(0,16)}</td>
                                            <td className="text-center">{r.EndDate.replaceAll('-','/').replaceAll('T',' ').substring(0,16)}</td>
                                            <td className="text-center">{r.StartUser}</td>
                                            <td className="text-center">{r.EndUser}</td>
                                            <td className="text-center">{r.StationID}</td>
                                            <td className="text-center"><CurrencyFormat value={r.AmountStart} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                            <td className="text-center"><CurrencyFormat value={r.AmountEnd} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                            <td className="text-center pr-2"><CurrencyFormat value={r.AmountDifference} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Fragment>
    );
  }
  
  export default ReportRetDrawerAmount;