import React, { Fragment, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { auth } from '../config/firebaseConfig';
import { signOut } from "firebase/auth";
import dayjs from 'dayjs';
import logo from "../images/logo.png"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

export const PageUser = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userData] = useOutletContext();
    const [currentStoreIndex, setCurrentStoreIndex] = useState(sessionStorage.getItem("currentStoreIndex") || 0);
    const [currentStore, setCurrentStore] = useState(userData.stores[sessionStorage.getItem("currentStoreIndex") || 0]);
    const [dateFrom, setDateFrom] = useState(dayjs(sessionStorage.getItem("dateFrom") || new Date()));
    const [dateTo, setDateTo] = useState(dayjs(sessionStorage.getItem("dateTo") ||  new Date()));
    const [timeFrom, setTimeFrom] = useState(dayjs(sessionStorage.getItem("timeFrom") || "9999-01-01T00:00"));
    const [timeTo, setTimeTo] = useState(dayjs(sessionStorage.getItem("timeTo") || "9999-01-01T23:59"));
    const now = new Date();

    const navigateReport = (e) => {
        e.preventDefault();
        navigate("/report", {state:{
            id: e.target.id,
            storeName: currentStore.name,
            dateFrom: dateFrom.format('YYYY-MM-DD').toString(),
            dateTo: dateTo.format('YYYY-MM-DD').toString(),
            timeFrom: timeFrom.format('HH:mm').toString(),
            timeTo: timeTo.format('HH:mm').toString(),
            storeType: currentStore.type,
            ip: currentStore.ip
        }});
        sessionStorage.setItem("currentStoreIndex", currentStoreIndex);
        sessionStorage.setItem("dateFrom", dateFrom);
        sessionStorage.setItem("dateTo", dateTo);
        sessionStorage.setItem("timeFrom", timeFrom);
        sessionStorage.setItem("timeTo", timeTo);
    }

    const onLogout = (e) => {
        e.preventDefault();
        setLoading(true);
        signOut(auth).then(() => {
            sessionStorage.clear();
            navigate("/login");
        }).catch((error) => {
            console.log(error);
        });
        setLoading(false);
    }

    const setToday = () => {
        setDateFrom(dayjs()); setDateTo(dayjs());
    }

    function setThisWeek(){
        var first = now.getDate() - now.getDay();
        var sunday = new Date(now.setDate(first));
        var saturday = new Date(sunday);
        saturday.setDate(saturday.getDate()+6);
        setDateFrom(dayjs(sunday));
        setDateTo(dayjs(saturday));
    }

    function setThisMonth(){
        setDateFrom(dayjs(new Date(now.getFullYear(), now.getMonth(), 1)));
        setDateTo(dayjs(new Date(now.getFullYear(), now.getMonth()+1, 0)));
    }

    function setThisYear(){
        setDateFrom(dayjs(new Date(now.getFullYear(),0,1)));
        setDateTo(dayjs(new Date(now.getFullYear(),11,31)));
    }

    function setYesterday(){
        var yesterday = new Date(now);
        yesterday.setDate(yesterday.getDate()-1);
        setDateFrom(dayjs(yesterday));
        setDateTo(dayjs(yesterday));
    }
    function setLastWeek(){
        var first = now.getDate() - now.getDay() -7;
        var sunday = new Date(now.setDate(first));
        var saturday = new Date(sunday);
        saturday.setDate(saturday.getDate()+6);
        setDateFrom(dayjs(sunday));
        setDateTo(dayjs(saturday));
    }
    function setLastMonth(){
        var d = new Date(now.getFullYear(), now.getMonth(), 0);
        setDateFrom(dayjs(new Date(now.getFullYear(), now.getMonth()-1, 1)));
        setDateTo(dayjs(d));
    }
    function setLastYear(){
        setDateFrom(dayjs(new Date(now.getFullYear()-1,0,1)));
        setDateTo(dayjs(new Date(now.getFullYear()-1,11,31)));
    }

    return (
        <div className="flex items-center justify-center md:h-screen">
            <div className="block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
                <div className="flex justify-between bg-white-500 border-b-2 border-neutral-100 px-6 py-3">
                    <img className="h-10 pt-1" src={logo} alt="logo"/>
                    <button onClick={onLogout} disabled={loading} className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-red-400">Logout</button>
                </div>
                <div className="grid grid-cols-6">
                    <div className="col-span-6 mt-3">
                        <label className="block text-sm md:text-base font-medium text-gray-700"><strong>Email:</strong> {userData.email}</label>
                    </div>
                    <div className="col-span-6 mt-2">
                        <label className="block text-sm md:text-base font-medium text-gray-700"><strong>Store (Type):</strong> {currentStore.name} ({currentStore.type})</label>
                    </div>
                    <div className="col-span-6 m-3">
                        <select className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                            required={true}
                            value={currentStoreIndex}
                            onChange={(e) => {setCurrentStoreIndex(e.target.value); setCurrentStore(userData.stores[e.target.value]);}}>
                            {/* <option value="" disabled>Select Store</option> */}
                            {userData.stores.map((s, index) => <option key={index} value={index}>{s.name}</option>)}
                        </select>
                    </div>
                    <div className="col-span-3 m-2">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label="From" value={dateFrom} onChange={(newValue) => setDateFrom(newValue)}
                            slotProps={{ textField: { size: 'small', inputProps: { style: { textAlign: "center" }}}}}/>
                        </LocalizationProvider>
                    </div>
                    <div className="col-span-3 m-2">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label="To" value={dateTo} onChange={(newValue) => setDateTo(newValue)}
                            slotProps={{ textField: { size: 'small', inputProps: { style: { textAlign: "center" }}}}}/>
                        </LocalizationProvider>
                    </div>
                    <div className="col-span-3 m-2">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker label="From" value={timeFrom} onChange={(newValue) => setTimeFrom(newValue)}
                            slotProps={{ textField: { size: 'small', inputProps: { style: { textAlign: "center" }}}}}/>
                        </LocalizationProvider>
                    </div>
                    <div className="col-span-3 m-2">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker label="To" value={timeTo} onChange={(newValue) => setTimeTo(newValue)}
                            slotProps={{ textField: { size: 'small', inputProps: { style: { textAlign: "center" }}}}}/>
                        </LocalizationProvider>
                    </div>
                    <div className="col-span-6 my-2">
                        <button onClick={setToday} className="mr-1 sm:mx-1 text-xs sm:text-base bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-2 sm:px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400">
                            Today
                        </button>
                        <button onClick={setThisWeek} className="mr-1 sm:mx-1 text-xs sm:text-base bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-2 sm:px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400">
                            This Week
                        </button>
                        <button onClick={setThisMonth} className="mr-1 sm:mx-1 text-xs sm:text-base bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-2 sm:px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400">
                            This Month
                        </button>
                        <button onClick={setThisYear} className="sm:mx-1 text-xs sm:text-base bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-2 sm:px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400">
                            This Year
                        </button>
                    </div>
                    <div className="col-span-6 mb-2">
                        <button onClick={setYesterday} className="mr-1 sm:mx-1 text-xs sm:text-base bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-2 sm:px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400">
                            Yesterday
                        </button>
                        <button onClick={setLastWeek} className="mr-1 sm:mx-1 text-xs sm:text-base bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-2 sm:px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400">
                            Last Week
                        </button>
                        <button onClick={setLastMonth} className="mr-1 sm:mx-1 text-xs sm:text-base bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-2 sm:px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400">
                            Last Month
                        </button>
                        <button onClick={setLastYear} className="sm:mx-1 text-xs sm:text-base bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-2 sm:px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400">
                            Last Year
                        </button>
                    </div>
                    {
                        currentStore.type === "Retail" ? 
                        <Fragment>
                            <div className="col-span-6 mb-2">
                                <div id="sales_summary" onClick={navigateReport} className="mx-2 text-sm sm:text-base bg-purple-500 hover:bg-purple-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-purple-400">
                                    Sales Summary
                                </div>
                            </div>
                            <div className="col-span-6 mb-2">
                                <div id="inventory_list" onClick={navigateReport} className="mx-2 text-sm sm:text-base bg-purple-500 hover:bg-purple-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-purple-400">
                                    Inventory List
                                </div>
                            </div>
                            <div className="col-span-6 mb-2">
                                <div id="batch_settlement" onClick={navigateReport} className="mx-2 text-sm sm:text-base bg-purple-500 hover:bg-purple-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-purple-400">
                                    Batch Settlement
                                </div>
                            </div>
                            <div className="col-span-6 mb-2">
                                <div id="drawer_amount" onClick={navigateReport} className="mx-2 text-sm sm:text-base bg-purple-500 hover:bg-purple-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-purple-400">
                                    Drawer Amount
                                </div>
                            </div>
                            <div className="col-span-6 mb-2">
                                <div id="time_clock" onClick={navigateReport} className="mx-2 text-sm sm:text-base bg-purple-500 hover:bg-purple-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-purple-400">
                                    Time Clock Summary
                                </div>
                            </div>
                            <div className="col-span-6 mb-2">
                                <div id="sales_by_month" onClick={navigateReport} className="mx-2 text-sm sm:text-base bg-purple-500 hover:bg-purple-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-purple-400">
                                    Sales By Month
                                </div>
                            </div>
                        </Fragment>
                        : currentStore.type === "Restaurant" ?
                        <Fragment>
                            <div className="col-span-6 mb-2">
                                <div id="sales_summary" onClick={navigateReport} className="mx-2 text-sm sm:text-base bg-purple-500 hover:bg-purple-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-purple-400">
                                    Sales Summary
                                </div>
                            </div>
                            <div className="col-span-6 mb-2">
                                <div id="batch_settlement" onClick={navigateReport} className="mx-2 text-sm sm:text-base bg-purple-500 hover:bg-purple-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-purple-400">
                                    Batch Settlement
                                </div>
                            </div>
                            <div className="col-span-6 mb-2">
                                <div id="server_summary" onClick={navigateReport} className="mx-2 text-sm sm:text-base bg-purple-500 hover:bg-purple-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-purple-400">
                                    Server Summary
                                </div>
                            </div>
                            <div className="col-span-6 mb-2">
                                <div id="tips_by_employee" onClick={navigateReport} className="mx-2 text-sm sm:text-base bg-purple-500 hover:bg-purple-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-purple-400">
                                    Tips By Employee
                                </div>
                            </div>
                            <div className="col-span-6 mb-2">
                                <div id="time_clock_simple" onClick={navigateReport} className="mx-2 text-sm sm:text-base bg-purple-500 hover:bg-purple-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-purple-400">
                                    Time Clock
                                </div>
                            </div>
                            <div className="col-span-6 mb-2">
                                <div id="inventory_list_res" onClick={navigateReport} className="mx-2 text-sm sm:text-base bg-purple-500 hover:bg-purple-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-purple-400">
                                    Inventory List
                                </div>
                            </div>
                        </Fragment>
                        : <></>
                    }
                </div>
                <div className="flex items-center justify-center px-6 py-4 space-x-2rounded-b">
                    
                </div>
            </div>
        </div>
    );
  }

  export default PageUser;