import React, { useState } from "react";
import show from "../images/show.png";
import hide from "../images/hide.png";
import { auth2, db } from '../config/firebaseConfig';
import { doc, setDoc } from "firebase/firestore"; 
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, updateEmail, updatePassword } from "firebase/auth";
import { Table } from './Table';
import { ActionStatusDialog } from './ActionStatusDialog';
import { ModalStore } from "./ModalStore";

const tableColumns = [
    { key: "name", header: "Name" },
    { key: "type", header: "Type" },
    { key: "ip", header: "IP" }
];

const addStoreFormValues = {
    name: "",
    type: "",
    ip: ""
};

export const ModalUser = ({ userData, setIsModalOpen, isModalOpen, setActionModal, fetchUsers, configureFormData }) => {
    const [user, setUser] = useState(userData);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const modalType = isModalOpen.modalType;
    const originalEmail = userData.email;
    const originalPassword = userData.password;

    const addStore = (newStore) => {
        const stores = user.stores;
        stores.push(newStore);
        setUser({...user, "stores": stores });
    };

    const editStore = (index, storeData) => {
        var stores = user.stores;
        stores[index] = storeData;
        setUser({...user, "stores": stores });
    };

    const deleteStore = (index) => {
        const stores = user.stores;
        stores.splice(index, 1);
        setUser({...user, "stores": stores });
    };

    const [isModalOpen2, setIsModalOpen2] = useState({
        isOpen: false,
        modalType: "", // "add" | "edit"
        index: -1, //index of the data row being viewed.
    });
    const [actionModal2, setActionModal2] = useState({
        isOpen: false,
        successful: false, // true or false
    });

    let storeData = addStoreFormValues;
    if (isModalOpen2.isOpen && (isModalOpen2.modalType === "edit") && isModalOpen2.index >= 0) {
        storeData = user.stores[isModalOpen2.index];
    }

    function togglePasswordVisibility() { setIsPasswordVisible((prevState) => !prevState); }

    const handleInputChange = (e) => {
        const { name, value, checked } = e.target;
        if (name === "inactive") { setUser({...user, [name]: checked }); } 
        else { setUser({...user, [name]: value }); }
    };

    const resetForm = () => {
        fetchUsers();
        setIsModalOpen({ isOpen: false, modalType: "", index: -1 });
        setIsModalOpen2({ isOpen: false, modalType: "", index: -1 });
    }

    const firestoreSetUser = async (uid) => {
        await setDoc(doc(db, "users", uid), {
            email: user.email,
            password: user.password,
            user_type: user.user_type,
            notes: user.notes,
            inactive: user.inactive,
            stores: user.stores
        });
        console.log("User successfully saved.");
        setActionModal({ isOpen: true, successful: true });
        resetForm();
    }

    const onFormSubmit = async (e) => {
        e.preventDefault();
        if(modalType === "add"){
            createUserWithEmailAndPassword(auth2, user.email, user.password)
                .then((userCredential) => {
                    signOut(auth2).then(() => {
                        const credential = userCredential.user;
                        firestoreSetUser(credential.uid);
                    }).catch((error) => {
                        console.log("Error saving firestore: "+error);
                        setActionModal({ isOpen: true, successful: false });
                    });
                }).catch((error) => {
                    console.log("Error creating user: "+error);
                    setActionModal({ isOpen: true, successful: false });
                });
        } else if (modalType === "edit") {
            if(user.email !== originalEmail || user.password !== originalPassword){
                signInWithEmailAndPassword(auth2, originalEmail, originalPassword)
                    .then(async () => {
                        const editUser = auth2.currentUser;
                        await updateEmail(editUser, user.email);
                        await updatePassword(editUser, user.password);
                        await signOut(auth2);
                    }).catch((error) => {
                        console.log("Error getting credential : "+error);
                        setActionModal({ isOpen: true, successful: false });
                    });
            }
            try {
                firestoreSetUser(user.id);
            } catch (error) {
                console.log("Error saving firestore: "+error);
                setActionModal({ isOpen: true, successful: false });
            }
        }
    };

    return (
        <div className="relative w-full max-w-3xl max-h-full">
            {actionModal2.isOpen && (
                <ActionStatusDialog isSuccessful={actionModal2.successful} setActionModal={setActionModal2}/>
            )}
            <form onSubmit={onFormSubmit} className="relative bg-white rounded-lg shadow">
                <div className="flex items-start justify-between px-4 py-2 border-b rounded-t">
                    <h3 className="text-xl font-semibold text-gray-900">
                        {modalType} User
                    </h3>
                    <button
                        type="button"
                        className="text-black-400 bg-transparent hover:bg-gray-200 hover:text-black-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                        onClick={() => {resetForm();}}>
                        X
                    </button>
                </div>
                <div className="p-6 space-y-6">
                    <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                            <label className="block mb-2 text-sm font-medium text-gray-900 text-left">Email</label>
                            <input
                                type="text"
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                placeholder="jerry@posconnect.io"
                                required={true}
                                name="email"
                                value={user.email}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label className="block mb-2 text-sm font-medium text-gray-900 text-left">
                                Password
                            </label>
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-5">
                                    <input
                                        type={isPasswordVisible ? "text" : "password"}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                        placeholder="password"
                                        minLength="6"
                                        required={true}
                                        name="password"
                                        value={user.password}
                                        autoComplete="off"
                                        onChange={handleInputChange} />
                                </div>
                                <div className="col-span-1">
                                    <div onClick={togglePasswordVisibility} className="text-gray-600">
                                        {isPasswordVisible ? (
                                            <img className="w-10 opacity-30 hover:opacity-40" src={show} alt="show"/>
                                        ) : (
                                            <img className="w-10 opacity-30 hover:opacity-40" src={hide} alt="hide"/>
                                        )}
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-4 sm:col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 text-left">User Type</label>
                            <select className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                required={true}
                                name="user_type"
                                value={user.user_type}
                                onChange={handleInputChange} >
                                <option value="" disabled>Select User Type</option>
                                <option value="admin">Admin</option>
                                <option value="user">User</option>    
                            </select>
                        </div>
                        <div className="flex items-center col-span-2 sm:col-span-1">
                            <input
                                type="checkbox"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                                name="inactive"
                                onChange={handleInputChange}
                                defaultChecked={user.inactive}
                            />
                            <label className="ml-2 text-sm font-medium text-gray-900">
                                Inactive
                            </label>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label className="block mb-2 text-sm font-medium text-gray-900 text-left">Notes</label>
                            <input
                                type="text"
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                placeholder="notes"
                                required={false}
                                name="notes"
                                value={user.notes}
                                onChange={handleInputChange} />
                        </div>
                    </div>
                    <Table 
                        entityName="Store"
                        tableName="Stores" 
                        tableColumns={tableColumns} 
                        tableData={user.stores} 
                        setIsModalOpen={setIsModalOpen2}
                        deleteStore={deleteStore}
                    />
                    {isModalOpen2.isOpen ? (
                        <div aria-hidden="true" className={`fixed flex top-0 left-0 right-0 z-50 items-center justify-center w-screen h-screen p-4 overflow-x-hidden overflow-y-auto bg-black bg-opacity-25`}>
                        <ModalStore
                            storeData={storeData}
                            setIsModalOpen={setIsModalOpen2}
                            isModalOpen={isModalOpen2}
                            setActionModal={setActionModal2}
                            addStore={addStore}
                            editStore={editStore}
                        />
                        </div>
                    ) : ( <></> )}
                </div>
                <div className="flex items-center justify-end px-6 py-4 space-x-2 border-t border-gray-200 rounded-b">
                    <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400">
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};
