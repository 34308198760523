import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../config/firebaseConfig';
import ReportRetSalesSummary from './ReportRetSalesSummary';
import ReportRetBatchSettlement from './ReportRetBatchSettlement';
import ReportRetDrawerAmount from './ReportRetDrawerAmount';
import ReportRetTimeClockSummary from './ReportRetTimeClockSummary';
import ReportRetSalesByMonth from './ReportRetSalesByMonth';
import ReportRetInventoryList from './ReportRetInventoryList';
import ReportResSalesSummary from './ReportResSalesSummary';
import ReportResBatchSettlement from './ReportResBatchSettlement';
import ReportResServerSummary from './ReportResServerSummary';
import ReportResTipsByEmployee from './ReportResTipsByEmployee';
import ReportResTimeClock from './ReportResTimeClock';
import ReportResInventoryList from './ReportResInventoryList';

const REPORT_LIBRARY = { 
    sales_summary : "Sales Summary",
    inventory_list : "Inventory List",
    batch_settlement : "Batch Settlement",
    drawer_amount : "Drawer Amount",
    time_clock : "Time Clock",
    sales_by_month : "Sales By Month",
    server_summary : "Server Summary",
    time_clock_simple : "Time Clock",
    tips_by_employee: "Tips By Employee",
    inventory_list_res: "Inventory List"
}

const API_URL = "https://us-central1-pos-web-report.cloudfunctions.net/api"
//const API_URL = "http://localhost:3001"

export const PageReport = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentUser = auth.currentUser;
    const reportId = location.state.id;
    const from = location.state.dateFrom + " " + location.state.timeFrom;
    const to = location.state.dateTo + " " + location.state.timeTo;
    const storeType = location.state.storeType.substring(0,3).toLowerCase();
    const ip = location.state.ip;
    const [reportData, setReportData] = useState({});
    const [error, setError] = useState('');
    const now = new Date();

    const getReq = async (url) => {
        const response = await fetch(url, { method: 'GET', headers: { firebase_token_id: await currentUser.getIdToken() }});
        const data = await response.json();
        return data;
    }

    const fetchReportData = async () => {
        console.log("fetching report data...");
        try {
            // Do not run the report here
            if (['inventory_list', 'server_summary', 'time_clock_simple'].indexOf(reportId) !== -1) return;

            var data = await getReq(`${API_URL}/${storeType}/${reportId}/?d1=${from.replace(' ','%20')}:00&d2=${to.replace(' ','%20')}:59&ip=${ip}`);
            if (!data.error) {
                setReportData(data);
            } else {
                setError(!data.error ? "" : data.error);
            }
        } catch (error) {
            console.error('Error connecting POS DB:', error);
        }
    }
    
    useEffect(() => {
        fetchReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const preloadingCheck = () => {
        if(JSON.stringify(reportData) !== "{}") return true;
        if(error !== "") return true;
        return false;
    }

    return (
        <div className="flex items-center justify-center">
            <div className="p-5 block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
                <div className="grid grid-cols-10 gap-8">
                    <div className="col-span-10 sm:col-span-5 print:col-span-5">
                        <div className="text-2xl md:text-3xl lg:text-4xl font-semibold text-gray-900 text-left">
                            {REPORT_LIBRARY[reportId]}
                        </div>
                        <div className="flex mt-2">
                            <button onClick={() => {navigate(-1);}} className="print:hidden bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400 mr-4">
                                Back
                            </button>
                            <button onClick={() => {window.print()}} className="print:hidden bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400 mr-4">
                                Print
                            </button>
                            {/* <button onClick={() => window.print()} className="section-no-print bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400">
                                Print
                            </button> */}
                        </div>
                    </div>
                    <div className="col-span-10 sm:col-span-5 print:col-span-5">
                        <div className="text-lg md:text-xl lg:text-2xl font-semibold text-gray-900 text-right">
                            {location.state.storeName}
                        </div>
                        <div className="text-sm md:text-base  font-semibold text-gray-900 text-right">
                            Generated: {now.toLocaleString()}
                        </div>
                        <div className="text-sm md:text-base font-semibold text-gray-900 text-right">
                            {from} ~ {to}
                        </div>
                    </div>
                    { error ? 
                        <div className="col-span-10">
                            <p className="text-sm md:text-base font-semibold text-[#FF0000]">Error : {error}</p> 
                        </div>
                        : preloadingCheck() && storeType === "ret" && reportId === "sales_summary" ? <ReportRetSalesSummary reportData={reportData}/>
                        : storeType === "ret" && reportId === "inventory_list" ? <ReportRetInventoryList ip={ip}/>
                        : preloadingCheck() && storeType === "ret" && reportId === "batch_settlement" ? <ReportRetBatchSettlement reportData={reportData}/>
                        : preloadingCheck() && storeType === "ret" && reportId === "drawer_amount" ? <ReportRetDrawerAmount reportData={reportData}/>
                        : preloadingCheck() && storeType === "ret" && reportId === "time_clock" ? <ReportRetTimeClockSummary reportData={reportData}/>
                        : preloadingCheck() && storeType === "ret" && reportId === "sales_by_month" ? <ReportRetSalesByMonth reportData={reportData}/>
                        : preloadingCheck() && storeType === "res" && reportId === "sales_summary" ? <ReportResSalesSummary reportData={reportData}/>
                        : preloadingCheck() && storeType === "res" && reportId === "batch_settlement" ? <ReportResBatchSettlement reportData={reportData}/>
                        : storeType === "res" && reportId === "server_summary" ? <ReportResServerSummary ip={ip} from={from} to={to}/>
                        : preloadingCheck() && storeType === "res" && reportId === "tips_by_employee" ? <ReportResTipsByEmployee reportData={reportData}/>
                        : storeType === "res" && reportId === "time_clock_simple" ? <ReportResTimeClock ip={ip} from={from} to={to}/>
                        : preloadingCheck() && storeType === "res" && reportId === "inventory_list_res" ? <ReportResInventoryList reportData={reportData}/>
                        : 
                        <div role="status" className="col-span-10">
                            <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
  }
  
  export default PageReport;