import React, { useState } from "react";

export const ModalStore = ({ storeData, setIsModalOpen, isModalOpen, addStore, editStore, setActionModal }) => {
    const [store, setStore] = useState(storeData);
    const [error, setError] = useState('');
    const modalType = isModalOpen.modalType;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setStore({ ...store, [name]: value });
    };

    const validate = () => {
        var isValid = true;
        setError('');
        if(!store.name){
            setError("Store Name is required.");
            isValid = false;
        } else if(!store.type) {
            setError("Store Type is required");
            isValid = false;
        } else if(!store.ip) {
            setError("IP is required");
            isValid = false;
        }
        return isValid;
    }

    const onSave = (e) => {
        e.preventDefault();
        if(!validate()) return;

        if (modalType === "add") {
            addStore(store);
        } else if (modalType === "edit") {
            editStore(isModalOpen.index, store);
        }
        setIsModalOpen({ isOpen: false, modalType: "", index: -1});
    };

    return (
        <div className="relative w-80 max-h-full">
            <div className="relative bg-white rounded-lg shadow">
                <div className="flex items-start justify-between px-4 py-2 border-b rounded-t">
                    <h3 className="text-xl font-semibold text-gray-900">
                        {modalType} Store
                    </h3>
                    <button
                        type="button"
                        className="text-black-400 bg-transparent hover:bg-gray-200 hover:text-black-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                        onClick={() => {setIsModalOpen({ isOpen: false, modalType: "", index: -1 })}}>
                        X
                    </button>
                </div>
                <div className="p-6 space-y-6">
                    <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900 text-left">Store Name</label>
                            <input
                                type="text"
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                placeholder="Store Name"
                                required={true}
                                name="name"
                                value={store.name}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="col-span-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900 text-left">Store Type</label>
                            <select className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                required
                                name="type"
                                value={store.type}
                                onChange={handleInputChange} >
                                <option value="" disabled>Select Store Type</option>
                                <option value="Retail">Retail</option>
                                <option value="Restaurant">Restaurant</option>    
                            </select>
                        </div>
                        <div className="col-span-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900 text-left">IP</label>
                            <input
                                type="text"
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                placeholder="192.168.1.1"
                                required={true}
                                value={store.ip}
                                name="ip"
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    { error ? <p className="text-red-600">{error}</p> : <></>}
                </div>
                <div className="flex items-center justify-end px-6 py-4 space-x-2 border-t border-gray-200 rounded-b">
                    <button onClick={onSave} className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400">
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};
