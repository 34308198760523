import React, { useEffect, useState } from 'react';
import { NavBar } from "./NavBar";
import { Table } from './Table';
import { EntityStatus } from "../utils/utils";
import { db } from '../config/firebaseConfig';
import { ModalUser } from './ModalUser';
import { collection, getDocs } from "firebase/firestore"; 
import { ActionStatusDialog } from './ActionStatusDialog';

const tableColumns = [
    { key: "email", header: "Email" },
    { key: "user_type", header: "User Type" },
    { key: "notes", header: "Notes" },
    { key: "inactive", header: "Status", cell: (row) => <EntityStatus inactive={row.inactive} /> }
];

export const PageAdmin = () => {
    const [users, setUsers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState({
        isOpen: false,
        modalType: "", // "add" | "edit"
        index: -1, //index of the data row being viewed.
    });
    const [actionModal, setActionModal] = useState({
        isOpen: false,
        successful: false, // true or false
    });

    let userData = { id: "", email: "", password: "", user_type: "", notes: "", inactive: false, stores: [] };
    if (isModalOpen.isOpen && (isModalOpen.modalType === "edit") && isModalOpen.index >= 0) {
        userData = users[isModalOpen.index];
    }

    const fetchUsers = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "users"));
            const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setUsers(data);
        } catch (error) {
            console.error('Error fetching Firestore data:', error);
        }
    };
    
    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <div>
            <NavBar/>
            {actionModal.isOpen && (
                <ActionStatusDialog
                    isSuccessful={actionModal.successful}
                    setActionModal={setActionModal}
                />
            )}
            <div className="md:mx-20">
                <Table
                    entityName="User"
                    tableName="USER MANAGEMENT" 
                    tableColumns={tableColumns} 
                    tableData={users} 
                    setIsModalOpen={setIsModalOpen}
                />
            </div>
            {isModalOpen.isOpen ? (
                <div aria-hidden="true" className={`fixed flex top-0 left-0 right-0 z-50 items-center justify-center w-screen h-screen p-2 overflow-x-hidden overflow-y-auto bg-black bg-opacity-25`}>
                <ModalUser
                    userData={userData}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    setActionModal={setActionModal}
                    fetchUsers={fetchUsers}
                />
                </div>
            ) : ( <></> )}
        </div>
    );
  }
  
  export default PageAdmin;