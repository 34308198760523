export const EntityStatus = ({ inactive }) => {
    return inactive === false ? (
        <span className="bg-green-100 text-green-800 text-md font-medium mr-2 px-2.5 py-0.5 rounded">
            Active
        </span>
    ) : (
        <span className="bg-red-100 text-red-800 text-md font-medium mr-2 px-2.5 py-0.5 rounded">
            Inactive
        </span>
    );
};