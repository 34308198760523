import React, { Fragment, useState } from 'react';
import CurrencyFormat from 'react-currency-format';

const tableColumns = [
    { header: "Time In" },
    { header: "Time Out" },
    { header: "Time Total" },
    { header: "Reg. Wage" },
    { header: "O.T. Wage" },
    { header: "Total Wage" },
    { header: "Hrly Rate" },
    { header: "O.T. Rate" }
];

export const ReportRetTimeClockSummary = ({reportData}) => {
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [timeclock, setTimeClock] = useState(reportData.timeclock);
    var employeeLibrary = {};
    var employees = Array.from(new Set(reportData.timeclock.map(t => {
        employeeLibrary[t.EmployeeIDN] = t.FName1;
        return t.EmployeeIDN;
    }))).sort();

    function calcMin(hrs){
        let temp = Number((hrs - (parseInt(hrs))).toFixed(2))
        return Math.round(temp*60)
    }

    const employeeListener = (e) =>{
        setSelectedEmployee(e.target.value);
        if(e.target.value !== ""){
            setTimeClock(reportData.timeclock.filter(t => parseInt(t.EmployeeIDN) === parseInt(e.target.value)));
        }else{
            setTimeClock(reportData.timeclock);
        }
    }

    return (
        <Fragment>
            <div className="col-span-10">
                <div className="rounded-lg border-black border px-4 print:border-none print:px-0">
                    <div className="py-3 border-b border-black">
                        <div className="text-xl md:text-2xl font-semibold text-gray-900">
                            <div className="col-span-4 sm:col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 text-left">Choose Employee</label>
                                <select onChange={employeeListener} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5">
                                    <option value="">Everyone</option>
                                    { employees.map(e => <option key={e} value={e}>{employeeLibrary[e]}</option>) }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-3 sm:max-h-[70vh] print:overflow-x-visible">
                        <table className="text-xs text-left text-gray-500">
                            <thead className="text-xs md:text-sm lg:text-base font-semibold text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    {tableColumns.map((col, i) => {
                                        return (<th key={"col"+i} scope="col" className="text-center px-2 py-4">{col.header}</th>);
                                    })}
                                </tr>
                            </thead>
                            <tbody className="text-sm md:text-base font-semibold text-gray-700 uppercase bg-gray-50">
                                {employees.map((r, i) => {
                                    if(selectedEmployee !== "" && parseInt(selectedEmployee) !== parseInt(r)) return <Fragment key={"row"+i}></Fragment>;
                                    const data = timeclock.filter(t => r === t.EmployeeIDN);
                                    const temptime = [];
                                    return (
                                        <Fragment key={"row"+i}>
                                            <tr className="text-[#008000] font-bold"><td className="text-center px-2">{employeeLibrary[r]}</td><td/><td/><td/><td/><td/><td/><td/></tr>
                                            {data.map((d, i)=> {
                                                temptime.push(d)
                                                return (
                                                    <tr key={"roww"+i}className="bg-white border-b hover:bg-gray-50">
                                                        <td className="text-center px-2 py-1">{d.TimeIn.replaceAll('-','/').replaceAll('T',' ').substring(0,16)}</td>
                                                        <td className="text-center px-2">{d.TimeOut.replaceAll('-','/').replaceAll('T',' ').substring(0,16)}</td>
                                                        <td className="text-center">{d.Hour} hr {d.Min} min (<CurrencyFormat value={d.TimeTotal} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>)</td>
                                                        <td className="text-center"><CurrencyFormat value={d.WageRegular} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                                        <td className="text-center"><CurrencyFormat value={d.WageOvertime} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                                        <td className="text-center"><CurrencyFormat value={d.Wage} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                                        <td className="text-center">{d.HourlyRate}</td>
                                                        <td className="text-center">{d.OvertimeRate}</td>
                                                    </tr>
                                                )
                                            })}
                                            <tr className="table-borderless border-top border-dark text-[#008000] font-bold">
                                                <td></td><td></td>
                                                <td className="text-center py-1">{Math.floor(temptime.reduce((a,v)=> a=a+parseFloat(v.TimeTotal),0))} hr {calcMin(Number((temptime.reduce((a,v)=> a=a+parseFloat(v.TimeTotal),0))).toFixed(2))} min
                                                (<CurrencyFormat value={temptime.reduce((a,v)=> a=a+parseFloat(v.TimeTotal),0)} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>)</td>
                                                <td className="text-center"><CurrencyFormat value={temptime.reduce((a,v)=> a=a+parseFloat(v.WageRegular),0)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                                <td className="text-center"><CurrencyFormat value={temptime.reduce((a,v)=> a=a+parseFloat(v.WageOvertime),0)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                                <td className="text-center"><CurrencyFormat value={temptime.reduce((a,v)=> a=a+parseFloat(v.Wage),0)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                                <td className="text-center">{temptime.reduce((a,v)=> a=a+parseFloat(v.HourlyRate),0)}</td>
                                                <td className="text-center">{temptime.reduce((a,v)=> a=a+parseFloat(v.OvertimeRate),0)}</td>
                                            </tr>
                                        </Fragment>
                                    )
                                })}
                            </tbody>
                            <tfoot className="text-sm md:text-base font-semibold text-gray-700 uppercase bg-gray-50">
                                <tr className="table-borderless border-t border-black text-[#00f] font-bold">
                                    <td></td><td className="text-center py-2">TOTAL</td>
                                    <td className="text-center">{Math.floor(timeclock.reduce((a,v)=> a=a+parseFloat(v.TimeTotal),0))} hr {calcMin(Number((timeclock.reduce((a,v)=> a=a+parseFloat(v.TimeTotal),0))).toFixed(2))} min
                                    (<CurrencyFormat value={timeclock.reduce((a,v)=> a=a+parseFloat(v.TimeTotal),0)} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>)</td>
                                    <td className="text-center"><CurrencyFormat value={timeclock.reduce((a,v)=> a=a+parseFloat(v.WageRegular),0)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                    <td className="text-center"><CurrencyFormat value={timeclock.reduce((a,v)=> a=a+parseFloat(v.WageOvertime),0)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                    <td className="text-center"><CurrencyFormat value={timeclock.reduce((a,v)=> a=a+parseFloat(v.Wage),0)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                    <td></td><td></td>
                                </tr>
                            </tfoot>       
                        </table>
                    </div>
                </div>
            </div>
        </Fragment>
    );
  }
  
  export default ReportRetTimeClockSummary;