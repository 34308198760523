import React, { useEffect, useState } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { Outlet, useNavigate } from 'react-router-dom';
import { auth, db } from '../config/firebaseConfig';
import { doc, getDoc } from "firebase/firestore";
import PageUserInactive from "./PageUserInactive";

export const PageHome = () => {
    const navigate = useNavigate();
    const [isUser, setIsUser] = useState(false);
    const [userData, setUserData] = useState({});
    const [userInactive, setUserInactive] = useState(false);

    useEffect(()=>{
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                console.log("Checking user...");
                const data = (await getDoc(doc(db, "users", user.uid))).data();
                const userType = data.user_type;

                setIsUser(true);
                setUserData(data);
                setUserInactive(data.inactive);

                if (userType === "admin") {
                  navigate("/admin");
                } else {
                  navigate("/home");
                }
            } else {
                setIsUser(false);
                console.log("user is not logged in");
                navigate("/login");
            }
          });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
      !isUser ? <></> : userInactive ? <PageUserInactive/> :<Outlet context={[userData]}/>
    );
  }
  
  export default PageHome;