import React, { Fragment } from 'react';
import CurrencyFormat from 'react-currency-format';

export const ReportResSalesSummary = ({reportData}) => {
    return (
        <Fragment>
            <div className="col-span-10 md:col-span-5 print:col-span-5 -m-1 sm:m-0">
                <div className="rounded-lg border-black border px-0 sm:px-4">
                    <div className="p-3 border-b border-black">
                        <div className="text-xl md:text-2xl font-semibold text-gray-900">
                            Sales by Category
                        </div>
                    </div>
                    <div className="p-3">
                        <div className="grid grid-cols-10 gap-x-4 sm:gap-x-8 gap-y-3">
                            {reportData.category && reportData.category.map((cat, i) => {
                                return (<Fragment key={"cat"+i}>
                                    <div className="text-xs sm:text-sm md:text-base col-span-6 font-semibold text-right">{cat.Name}</div>
                                    <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right"><CurrencyFormat value={cat.Subtotal} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></div>
                                </Fragment>)
                            })}
                        </div>
                    </div>
                    <div className="p-3 border-t border-black">
                        <div className="grid grid-cols-10 gap-x-4 sm:gap-x-8 gap-y-3">
                            {reportData.category ? <Fragment>
                                <div className="text-xs sm:text-sm md:text-base col-span-6 font-semibold text-right">Subtotal:</div>
                                <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right"><CurrencyFormat value={reportData.category.reduce((a,v)=> a=a+v.Subtotal,0)} displayType={'text'} thousandSeparator={true} prefix={'$'}  decimalScale={2} fixedDecimalScale={true}/></div>
                                <div className="text-xs sm:text-sm md:text-base col-span-6 font-semibold text-right">Discount:</div>
                                <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right text-[#FF0000]"><CurrencyFormat value={reportData.category.reduce((a,v)=> a=a+v.Discount,0)} displayType={'text'} thousandSeparator={true} prefix={'$'}  decimalScale={2} fixedDecimalScale={true}/></div>
                                <div className="text-xs sm:text-sm md:text-base col-span-6 font-semibold text-right">GC/Cert:</div>
                                <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right text-[#FF0000]"><CurrencyFormat value={reportData.category.reduce((a,v)=> a=a+v.GiftcardAmount,0)} displayType={'text'} thousandSeparator={true} prefix={'-$'}  decimalScale={2} fixedDecimalScale={true}/></div>
                            </Fragment>: <></>}
                        </div>
                    </div>
                    <div className="p-3 border-t border-black">
                        <div className="grid grid-cols-10 gap-x-4 sm:gap-x-8 gap-y-3">
                            {reportData.category ? <Fragment>
                                <div className="text-sm md:text-base col-span-6 font-semibold text-right">NetSales:</div>
                                <div className="text-sm md:text-base col-span-4 font-semibold text-right text-[#00f]">
                                    <CurrencyFormat value={reportData.category.reduce((a,v)=> a=a+v.Subtotal,0) + reportData.category.reduce((a,v)=> a=a+v.Discount,0) - reportData.category.reduce((a,v)=> a=a+v.GiftcardAmount,0)} 
                                                    displayType={'text'} thousandSeparator={true} prefix={'$'}  decimalScale={2} fixedDecimalScale={true}/>
                                </div>
                            </Fragment>: <></>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-10 md:col-span-5 print:col-span-5">
                <div className="rounded-lg border-black border px-0 sm:px-4">
                    <div className="p-3 border-b border-black">
                        <div className="text-xl md:text-2xl font-semibold text-gray-900">
                            Payments
                        </div>
                    </div>
                    <div className="px-1 py-2 sm:p-3">
                        <div className="grid grid-cols-10 gap-x-3 sm:gap-x-8 gap-y-3">
                            <div className="text-xs sm:text-sm md:text-base col-span-7 font-semibold text-right">Sales / Tips</div>
                            <div className="text-xs sm:text-sm md:text-base col-span-3 font-semibold text-right">Total</div>
                            {reportData.income && reportData.income.map((inc, i) => {
                                return (<Fragment key={"income"+i}>
                                    <div className="text-xs sm:text-sm md:text-base col-span-3 font-semibold text-right">{inc.Name}</div>
                                    <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-center">
                                        <CurrencyFormat value={inc.Subtotal} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/>
                                        /<CurrencyFormat className="text-[#00f]" value={inc.Tip} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                    </div>
                                    <div className="text-xs sm:text-sm md:text-base col-span-3 font-semibold text-right">
                                        <CurrencyFormat value={inc.Total} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/>
                                    </div>
                                </Fragment>)
                            })}
                        </div>
                    </div>
                    <div className="px-1 py-2 sm:p-3 border-t border-black">
                        <div className="grid grid-cols-10 gap-x-3 sm:gap-x-8 gap-y-3">
                            <div className="text-xs sm:text-sm md:text-base col-span-3 font-semibold text-right">Total:</div>
                            <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right sm:text-center">
                                <CurrencyFormat value={reportData.income.reduce((a,v)=> a=a+v.Subtotal,0)} displayType={'text'} thousandSeparator={true} prefix={'$'}  decimalScale={2} fixedDecimalScale={true}/>
                                /<CurrencyFormat className="text-[#00f]" value={reportData.income.reduce((a,v)=> a=a+v.Tip,0)} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>      
                            </div>
                            <div className="text-xs sm:text-sm md:text-base col-span-3 font-semibold text-right">
                                <CurrencyFormat value={reportData.income.reduce((a,v)=> a=a+v.Total,0)} displayType={'text'} thousandSeparator={true} prefix={'$'}  decimalScale={2} fixedDecimalScale={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="px-3 py-2 border-t border-black">
                        <div className="grid grid-cols-10 gap-x-4 sm:gap-x-8 gap-y-3">
                            <div className="text-xs sm:text-sm md:text-base col-span-7 font-semibold text-right">Tips:</div>
                            <div className="text-xs sm:text-sm md:text-base col-span-3 font-semibold text-right text-[#FF0000]">
                                <CurrencyFormat className="text-red" value={reportData.income.reduce((a,v)=> a=a+v.Tip,0)} displayType={'text'} thousandSeparator={true} prefix={'-$'}  decimalScale={2} fixedDecimalScale={true}/>
                            </div>
                            <div className="text-xs sm:text-sm md:text-base col-span-7 font-semibold text-right">Service Charges:</div>
                            <div className="text-xs sm:text-sm md:text-base col-span-3 font-semibold text-right text-[#FF0000]">
                                <CurrencyFormat className="text-red" value={reportData.taxAndServiceCharge.reduce((a,v)=> a=a+v.ServiceCharge,0)} displayType={'text'} thousandSeparator={true} prefix={'-$'}  decimalScale={2} fixedDecimalScale={true}/>
                            </div>
                            <div className="text-xs sm:text-sm md:text-base col-span-7 font-semibold text-right">Sales Minus Tips:</div>
                            <div className="text-xs sm:text-sm md:text-base col-span-3 font-semibold text-right">
                                <CurrencyFormat value={reportData.income.reduce((a,v)=> a=a+v.Total,0)-reportData.income.reduce((a,v)=> a=a+v.Tip,0)-reportData.taxAndServiceCharge.reduce((a,v)=> a=a+v.ServiceCharge,0)}
                                    displayType={'text'} thousandSeparator={true} prefix={'$'}  decimalScale={2} fixedDecimalScale={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="px-3 py-2 border-t border-black">
                        <div className="grid grid-cols-10 gap-x-4 sm:gap-x-8 gap-y-3">
                            <div className="text-xs sm:text-sm md:text-base col-span-7 font-semibold text-right">Tax:</div>
                            <div className="text-xs sm:text-sm md:text-base col-span-3 font-semibold text-right text-[#FF0000]">
                                <CurrencyFormat value={reportData.taxAndServiceCharge.reduce((a,v)=> a=a+v.Tax,0)} displayType={'text'} thousandSeparator={true} prefix={'-$'}  decimalScale={2} fixedDecimalScale={true}/>
                            </div>
                            <div className="text-xs sm:text-sm md:text-base col-span-7 font-semibold text-right">GC/Cert:</div>
                            <div className="text-xs sm:text-sm md:text-base col-span-3 font-semibold text-right text-[#FF0000]">
                                <CurrencyFormat value={reportData.category.reduce((a,v)=> a=a+v.GiftcardAmount,0)} displayType={'text'} thousandSeparator={true} prefix={'-$'}  decimalScale={2} fixedDecimalScale={true}/>
                            </div>
                            <div className="text-xs sm:text-sm md:text-base col-span-7 font-semibold text-right">Net Sales:</div>
                            <div className="text-xs sm:text-sm md:text-base col-span-3 font-semibold text-right text-[#00f]">
                                <CurrencyFormat value={reportData.income.reduce((a,v)=> a=a+v.Total,0)-reportData.income.reduce((a,v)=> a=a+v.Tip,0)-reportData.taxAndServiceCharge.reduce((a,v)=> a=a+v.ServiceCharge,0)
                                    -reportData.taxAndServiceCharge.reduce((a,v)=> a=a+v.Tax,0)-reportData.category.reduce((a,v)=> a=a+v.GiftcardAmount,0)} 
                                    displayType={'text'} thousandSeparator={true} prefix={'$'}  decimalScale={2} fixedDecimalScale={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="px-3 py-2 border-t border-black">
                        <div className="grid grid-cols-10 gap-x-4 sm:gap-x-8 gap-y-3">
                            <div className="text-xs sm:text-sm md:text-base col-span-7 font-semibold text-right">GC/Cert/Acc/Custom Payment:</div>
                            <div className="text-xs sm:text-sm md:text-base col-span-3 font-semibold text-right">
                                <CurrencyFormat value={reportData.income.filter((i)=> i.CategoryIDC ==="GC" || i.CategoryIDC ==="CR" || i.CategoryIDC ==="GT" || i.CategoryIDC ==="AC"
                                    || i.CategoryIDC ==="C1" || i.CategoryIDC ==="C2" || i.CategoryIDC ==="C3")
                                    .reduce((a,v)=> a=a+v.Subtotal,0)} displayType={'text'} thousandSeparator={true} prefix={'-$'}  decimalScale={2} fixedDecimalScale={true}/>
                            </div>
                            <div className="text-xs sm:text-sm md:text-base col-span-7 font-semibold text-right">Payout:</div>
                            <div className="text-xs sm:text-sm md:text-base col-span-3 font-semibold text-right">
                                <CurrencyFormat value={reportData.payout.reduce((a,v)=> a=a+v.Payout,0)} displayType={'text'} thousandSeparator={true} prefix={'$'}  decimalScale={2} fixedDecimalScale={true}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg border-black border px-4 mt-6">
                    <div className="p-3 border-b border-black">
                        <div className="text-xl md:text-2xl font-semibold text-gray-900">Discounts</div>
                    </div>
                    <div className="p-3">
                        <div className="grid grid-cols-10 gap-x-4 sm:gap-x-8 gap-y-3">
                            {reportData.discount && reportData.discount.map((d, i) => {
                                return (<Fragment key={"dept"+i}>
                                    <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right">{d.Name}</div>
                                    <div className="text-xs sm:text-sm md:text-base col-span-2 font-semibold text-right"><CurrencyFormat value={d.Qty} displayType={'text'} thousandSeparator={true}/></div>
                                    <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right text-[#FF0000]"><CurrencyFormat value={d.Total} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/></div>
                                </Fragment>)
                            })}
                        </div>
                    </div>
                    <div className="p-3 border-t border-black">
                        <div className="grid grid-cols-10 gap-x-4 sm:gap-x-8 gap-y-3">
                            {reportData.discount ? <Fragment>
                                <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right">Discount Total:</div>
                                <div className="text-xs sm:text-sm md:text-base col-span-2 font-semibold text-right"><CurrencyFormat value={reportData.discount.reduce((a,v)=> a=a+v.Qty,0)} displayType={'text'} thousandSeparator={true}/></div>
                                <div className="text-xs sm:text-sm md:text-base col-span-4 font-semibold text-right text-[#FF0000]"><CurrencyFormat className="text-red" value={reportData.discount.reduce((a,v)=> a=a+v.Total,0)} displayType={'text'} thousandSeparator={true} prefix={'$ '}  decimalScale={2} fixedDecimalScale={true}/></div>
                            </Fragment>: <></>}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
  }
  
  export default ReportResSalesSummary;