import React from "react";

export const Table = ({entityName, tableName, tableColumns, tableData, setIsModalOpen, deleteStore}) => {
    return (
        <div>
            <div className="flex items-center justify-between bg-white-500 mb-2 px-6 md:px-0">
                {tableName && <h1 className="text-1xl sm:text-3xl">{tableName}</h1>}
                {setIsModalOpen && 
                (<div onClick={() => { setIsModalOpen((prev) => { return { ...prev, isOpen: true, modalType: "add" }; }); }}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400">
                    Add {entityName}
                </div>)}
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            {tableColumns.map((col) => {
                                return (<th key={col.key} scope="col" className="px-6 py-3">{col.header}</th>);
                            })}
                            <th key={entityName+"action"} className="px-6 py-3"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((row, row_index) => {
                            return (
                                <tr key={entityName+row.id+row_index} className="bg-white border-b hover:bg-gray-50">
                                    {tableColumns.map((col, index) => {
                                        return (
                                            <td key={entityName+index} className="px-6 py-4">
                                                {col?.cell != null ? col.cell(row) : row[col.key]}
                                            </td>
                                        );
                                    })}
                                    <td key={entityName+row_index} className="py-4">
                                        { entityName === "Store" ?<>
                                            <label className="py-1.5 px-5 text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
                                                onClick={() => { setIsModalOpen({ isOpen: true, modalType: "edit", index: row_index}); }}>
                                                Edit
                                            </label>
                                            <label className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-1.5 ml-2"
                                                onClick={() => { deleteStore(row_index); }}>
                                                Delete
                                            </label></>
                                            : entityName === "User" ? 
                                            <label className="py-1.5 px-5 text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
                                            onClick={() => { setIsModalOpen({ isOpen: true, modalType: "edit", index: row_index}); }}>
                                                Edit
                                            </label>
                                            :<></>
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
