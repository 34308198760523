import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../config/firebaseConfig';
import { signOut } from "firebase/auth";

export const PageUser = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const onLogout = (e) => {
        e.preventDefault();
        setLoading(true);
        signOut(auth).then(() => {
            navigate("/login");
        }).catch((error) => {
            console.log(error);
        });
        setLoading(false);
    }

    return (
        <div className="grid h-screen place-items-center">
            <div>
                <h1 className="mb-4">Your account is inactivated. Please contact POSConnect.</h1>
                <button onClick={onLogout} disabled={loading} className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-red-400">Logout</button>
            </div>
        </div>
    );
  }
  
  export default PageUser;