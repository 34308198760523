import React, { Fragment } from 'react';
import CurrencyFormat from 'react-currency-format';

const tableColumns = [
    { header: "Date Settled" },
    { header: "Station #" },
    { header: "Item Count" },
    { header: "Batch Amount" },
    { header: "CC Total/Count" },
    { header: "Debit Total/Count" },
    { header: "EBT Total/Count" },
    { header: "Employee" }
];

export const ReportRetBatchSettlement = ({reportData}) => {
    return (
        <Fragment>
            <div className="col-span-10 print:-ml-4">
                <div className="rounded-lg border-black border px-3 print:border-none print:px-0">
                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg sm:max-h-[75vh] print:overflow-x-visible">
                        <table className="text-xs text-left text-gray-500">
                            <thead className="text-xs md:text-sm lg:text-base font-semibold text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    {tableColumns.map((col, i) => {
                                        return (<th key={"col"+i} scope="col" className="text-center px-2 py-3">{col.header}</th>);
                                    })}
                                </tr>
                            </thead>
                            <tbody className="text-sm md:text-base font-semibold text-gray-700 uppercase bg-gray-50">
                                {reportData.batch.map((r, i) => {
                                    return (
                                        <tr key={"row"+i} className="bg-white border-b hover:bg-gray-50">
                                            <td className="text-center pl-2 py-1">{r.CreateDate.replaceAll('-','/').replaceAll('T',' ').substring(0,16)}</td>
                                            <td className="text-center">{r.StationID}</td>
                                            <td className="text-center">{r.ItemCount}</td>
                                            <td className="text-center"><CurrencyFormat value={r.Balance} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></td>
                                            <td className="text-center"><CurrencyFormat value={r.TotalCreditCard} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/> /<CurrencyFormat className="text-[#00f]" value={r.CountCreditCard} displayType={'text'} thousandSeparator={true}/></td>
                                            <td className="text-center"><CurrencyFormat value={r.TotalDebit} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/> /<CurrencyFormat className="text-[#00f]" value={r.CountDebit} displayType={'text'} thousandSeparator={true}/></td>
                                            <td className="text-center"><CurrencyFormat value={r.TotalEBT} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/> /<CurrencyFormat className="text-[#00f]" value={r.CountEBT} displayType={'text'} thousandSeparator={true}/></td>
                                            <td className="text-center pr-2">{r.FName}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Fragment>
    );
  }
  
  export default ReportRetBatchSettlement;