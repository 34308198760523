
import React, { useState } from "react";
import logo from "../images/logo.png"
import { useNavigate } from 'react-router-dom';
import { auth } from '../config/firebaseConfig';
import { signOut } from "firebase/auth";

export const NavBar = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const onLogout = (e) => {
        e.preventDefault();
        setLoading(true);
        signOut(auth).then(() => {
            navigate("/login");
        }).catch((error) => {
            console.log(error);
        });
        setLoading(false);
    }

    return (
        <nav className="flex items-center justify-between flex-wrap bg-white-500 p-6">
            <div className="flex items-center flex-shrink-0 text-white mr-6">
                <img className="w-40" src={logo} alt="logo"/>
            </div>
            <button onClick={onLogout} disabled={loading} className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-red-400">Logout</button>
        </nav>
    );
};
