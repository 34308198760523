import React, { useState } from "react";
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../config/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import logo from "../images/logo.png";

export const PageLogin = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const onLogin = (e) => {
        e.preventDefault();
        setError(''); setLoading(true);
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                navigate("/");
            }).catch((error) => {
                switch(error.code){
                    case "auth/invalid-email":
                    case "auth/user-disabled":
                    case "auth/user-not-found":
                        setError(error.message)
                        break
                    case "auth/wrong-password":
                        setError(error.message)
                        break
                    default:
                        setError('Failed to log in')
                }
            });
        setLoading(false);
    }

    return (
      <div>
       <section className="bg-gray-50">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
                <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <img src={logo} alt="logo"/>
                        <form className="space-y-4 md:space-y-6" action="#" onSubmit={onLogin}>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900">Email</label>
                                <input 
                                    type="email" 
                                    name="email" 
                                    id="email" 
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="name@gmail.com" 
                                    required=""
                                    onChange={(e)=>setEmail(e.target.value)}
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900">Password</label>
                                <input 
                                    type="password" 
                                    name="password" 
                                    id="password" 
                                    placeholder="••••••••" 
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" 
                                    required=""
                                    onChange={(e)=>setPassword(e.target.value)}/>
                            </div>
                            { error ? <p className="text-red-600">{error}</p> : <></>}
                            <button type="submit" disabled={loading} className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Sign in</button>
                            <div className="w-100">
                                Contact POSConnect to make an account<br/>888) 905-0955
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
      </div>
    );
  }
  
  export default PageLogin;