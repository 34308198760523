import React, { useEffect, useState } from "react";

export const ActionStatusDialog = ({
  isSuccessful = false,
  setActionModal,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(false);
      setActionModal({
        isOpen: false,
        successful: false,
      });
    }, 3000);

    // To clear or cancel a timer, you call the clearTimeout(); method,
    // passing in the timer object that you created into clearTimeout().

    return () => clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isOpen) {
    return null;
  }

  return isSuccessful ? (
    <dialog
      className="rounded-lg p-4 mb-4 text-sm text-green-800 bg-green-200 fixed top-10 z-10"
      open
    >
      <span className="font-medium">Success!</span> Your action was completed
      successfully.
    </dialog>
  ) : (
    <dialog
      className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-200 fixed top-10 z-10"
      open
    >
      <span className="font-medium">Failed to complete action!</span> Please try
      again or contact the owners.
    </dialog>
  );
};
